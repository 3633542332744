import React, { useEffect, useState } from "react";
import FormInput from "../../components/UI/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";

const validationSchema = yup.object({
    first_name: yup
        .string("Enter your First Name")
        .min(3, "First Name should be minimum 3 characters")
        .required("First Name is required"),
    last_name: yup
        .string("Enter your Last Name")
        .min(3, "Last Name should be minimum 3 characters")
        .required("Last Name is required"),
    email: yup
        .string().email('Must be a valid Email')
        .required("Email is required"),
    phone_no: yup
        .string("Enter your Phone Number")
        .min(7, "Phone Number should be minimum 7 characters")
        .required("Phone Number is required"),
    message: yup.string("Enter your Message")
        .min(10, "Message should be minimum 10 characters")
        .required("Message is required"),
});

const From = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [send, setSend]=useState(false);
    const contactKeys = {
        wallet_id: "Wallet",
    };
    const formik = useFormik({
        initialValues: {
            first_name:"" ,
            last_name: "",
            email: "",
            phone_no: "",
            message: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(`https://mncsosuvbb.execute-api.us-east-2.amazonaws.com/contact/contact`, values)
                .then((response) => {
                    if (response.config.data) {
                        setSend(true)
                            enqueueSnackbar("Thank you for contacting us.", {
                                variant: "success",
                            });
                    } else {
                        enqueueSnackbar("An error occurred", {
                            variant: "error",
                        });
                    }
                })
                .catch((e) => {
                    enqueueSnackbar(`An error occurred`, {
                        variant: "error",
                    });
                });
        },
    })
    
    return (

        <div className="contact-form-wrap" >
              {(send == false) ? 
            <form id="contact-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                    
                    <div className="col-md-6 p-2">
                        <TextField
                            tag={'input'}
                            type={'text'}
                            name={'first_name'}
                            label={'First Name *'}
                            fullWidth
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            variant="outlined"
                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                            helperText={formik.touched.first_name && formik.errors.first_name}
                        />
                    </div>

                    <div className="col-md-6 p-2">
                        <TextField
                            tag={'input'}
                            type={'text'}
                            name={'last_name'}
                            label={'Last Name *'}
                            fullWidth
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            variant="outlined"
                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                            helperText={formik.touched.last_name && formik.errors.last_name}
                        />
                    </div>

                    <div className="col-md-6 p-2">
                        <TextField
                            tag={'input'}
                            type={'email'}
                            name={'email'}
                            label={'Email address *'}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            fullWidth
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </div>

                    <div className="col-md-6 p-2">
                        <TextField
                            tag={'input'}
                            type={'text'}
                            name={'phone_no'}
                            label="Phone Number"
                            className=""
                            value={formik.values.phone_no}
                            onChange={formik.handleChange}
                            fullWidth
                            error={formik.touched.phone_no && Boolean(formik.errors.phone_no)}
                            helperText={formik.touched.phone_no && formik.errors.phone_no}
                        />
                    </div>

                    <div className="col-12 p-2">
                        <TextField
                            fullWidth
                            name={'message'}
                            label={'Write Your Message *'}
                            type={'text'}
                            name={'message'}
                          
                            className=""
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            fullWidth
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}
                        />
                    <div className={"pt-3"}>
                         <FormInput
                            tag={'button'}
                            variant={"outlined"}
                        >
                            {"Send Message"}
                        </FormInput>
                        </div>
                        <div className="form-message " />
                    </div>
                </div>
            </form>
            :"Thank you for contacting us."}
        </div>
   
    );
};

export default From;