import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-two";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about.jpg'

const PageService = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="OUR SERVICES"
                content="We help companies that do good look good."
            />
            <PageAbout
                title={'Our Services'}
                heading="We offer a variety of services, designed with growth and unbelievable results in mind:"
                thumb={ServiceThumb}
                content="<strong>Software Development</strong> - Fast-track workflows, enhance revenues and boost business operations from concept-to-code and development-to-deployment <br/><br/> <strong>Digital Marketing </strong> - we all know the worth of a powerful marketing strategy, and its ability to form a strong voice for your company. <br/><br/> <strong>UI/UX Design </strong>  - Design and build digital products, bringing new ideas to life."
            />
            <Services classes="sm-top" />
            {/* <PricingTable /> */}
            {/* <Testimonial /> */}
            {/* <BrandLogo /> */}
            {/* <Funfact classes="sp-top" /> */}
            <CallToAction />
            <Footer />
            <LoginRegister />
            <MobileMenu />
        </Fragment>
    );
};

export default PageService;